import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { renderToString } from "react-dom/server";
import ErrorIcon from "../assets/icons/ErrorIcon.gif";
import SuccessIcon from "../assets/icons/SuccessIcon.gif";
import WarningIcon from "../assets/icons/WarningIcon.gif";

const MySwal = withReactContent(Swal);

export const Alert = (props, successfn, errorfn) => {
  // console.log('props Alert ', props)
  return MySwal.fire({
    iconHtml: GetIconAlert(props.icon),
    title: props.title,
    text: props.text,
    html: props.html,
    showClass: {
      icon: 'swal2-icon-alertNoti',
    },
    confirmButtonText: props.okbtntext ?? "Confirmar",
    customClass: {
      confirmButton: props.okbtnclass ?? "confirmButtonSweet",
      container: "backdropSweet",
      title: 'tituloNotificacion'
    },
    allowEscapeKey: false,
    allowOutsideClick: false
  })
    .then((result) => {
      if (props.customSuccessFn && result.isConfirmed) {
        props.customSuccessFn()
      } else {
        successfn && successfn()
      }
    })
    .catch(errorfn);
};

export const AlertConfirm = (props, successfn, errorfn) => {
  // console.log('props Alert Confirm ', props)
  return MySwal.fire({
    iconHtml: renderToString(GetIconAlert(props.icon)),
    title: props.title,
    text: props.text,
    html: renderToString(props.html),
    showClass: {
      icon: 'swal2-icon-alertNoti',
    },
    showCancelButton: true,
    confirmButtonText: props.okbtntext ?? "Confirmar",
    cancelButtonText: props.notbtntext ?? "Cancelar",
    customClass: {
      confirmButton: props.okbtnclass ?? "confirmButtonSweetConfirm",
      cancelButton: props.notbtnclass ?? "confirmButtonSweetCancel",
      container: "backdropSweet",
      title: 'tituloNotificacion'
    },
  })
    .then(successfn)
    .catch(errorfn);
};

const GetIconAlert = (type) => {
  switch (type) {
    case 'error':
      return <img src={ErrorIcon} alt="IMGError" className="swal2-icon-content-img" />
    case 'success':
      return <img src={SuccessIcon} alt="IMGSuccess" className="swal2-icon-content-img" />
    default:
      return <img src={WarningIcon} alt="IMGWarning" className="swal2-icon-content-img" />
  }
}